import { ApiClient } from '@je-pc/api'
import { SMARTGATEWAY_URL } from '../constants'

const http = new ApiClient(SMARTGATEWAY_URL, true, true)

export const getReviewsSummary = () => {
  return http.get('/applications/partnerreviews/{tenant}/{restaurantId}/reviewssummary')
}

export const getReviews = params => {
  return http.get(
    '/applications/partnerreviews/{tenant}/{restaurantId}/reviews',
    {
      params: {
        ...params,
        limit: 10
      }
    }
  )
}

export const submitResponse = (id, data) => {
  return http.put(
    `/applications/partnerreviews/{tenant}/{restaurantId}/reviews/${id}/restaurantcomments`,
    data
  )
}
