<template>
  <div class="flex flex-col">
    <p class="font-bold text-xl md:text-2xl" data-cy="noReviewText">
      {{ $t('you_dont_have_variant') }}
    </p>
    <p>{{ $t('try_to_motivate_variant') }}</p>
    <more-orders-more-reviews
      class="hidden sm:flex flex-grow-0 order-3 md:order-none mt-4"
    />
  </div>
</template>

<script>
import MoreOrdersMoreReviews from '../components/MoreOrdersMoreReviews'
export default {
  components: {
    MoreOrdersMoreReviews
  }
}
</script>
