<template>
  <div class="mt-4">

    <pie-button
      class="flex items-center cursor-pointer"
      size="xsmall"
      type="button"
      variant="secondary"
      isResponsive
      slot="reply"
      iconPlacement="leading"
      @click="handleEdit()"
      v-if="!isEditing"
      data-cy="clickReply"
    >
      <icon-chat-conversation slot="icon" class="iconOrange"></icon-chat-conversation>
      {{ $t('reply') }}
    </pie-button>

    <collapse-transition>
      <div v-if="isEditing">
        <p class="font-bold text-sm">{{ $t('your_response') }}</p>

        <textarea
          v-model="response"
          ref="review"
          class="w-full border border-grey-40 px-4 py-2 resize-none mt-2 mb-4 rounded-xl focus:outline-none focus:shadow-outline"
          data-cy="replyTextBox"
          rows="5"
        ></textarea>

        <p class="text-sm text-grey-50">{{ $t('all_reviews_checked') }}</p>
        <div class="mt-4 flex items-center sm:justify-end space-x-4">

          <pie-button
            type="button"
            size="xsmall"
            variant="outline"
            slot="Cancel"
            isResponsive
            @click="cancel"
            class="flex-1 sm:flex-none"
            data-cy="cancelButton"
          >
            {{ $t('cancel') }}
          </pie-button>

          <pie-button
            type="button"
            size="xsmall"
            variant="primary"
            slot="Reply"
            isResponsive
            @click="save"
            :loading="buttonLoading"
            class="flex-1 sm:flex-none"
            data-cy="saveButton"
          >
            {{ $t('reply') }}
          </pie-button>
        </div>
      </div>
    </collapse-transition>
    <fade-transition></fade-transition>
  </div>
</template>

<script>
import { CollapseTransition, FadeTransition } from '@je-pc/ui-components'
import '@justeattakeaway/pie-webc/components/button.js'
import '@justeattakeaway/pie-icons-webc/dist/IconChatConversation.js'
import { trackFormAction } from '../gtm'
import { SUBMIT_REVIEW } from '../store/actions.types'

export default {
  props: {
    id: {
      type: String,
      rquired: true
    }
  },
  data() {
    return {
      isEditing: false,
      response: '',
      buttonLoading: false
    }
  },
  components: {
    CollapseTransition,
    FadeTransition
  },
  methods: {
    cancel() {
      this.isEditing = false
    },
    async save() {
      this.buttonLoading = true
      const data = {
        id: this.id,
        restaurantComments: this.response
      }
      trackFormAction(this.id, 'submit', this.response)
      await this.$store.dispatch(SUBMIT_REVIEW, data)
      trackFormAction(this.id, 'success', this.response)
      this.isEditing = false
      this.response = ''
      this.buttonLoading = false
    },
    handleEdit() {
      this.isEditing = true
      this.$nextTick(() => {
        this.$refs.review.focus()
        this.$emit('edit')
      })
      trackFormAction(this.id, 'start')
    }
  }
}
</script>

<style scoped>
/* see https://pie.design/foundations/colour/tokens/alias/light/ */

.iconOrange {
  color: var(--dt-color-content-brand);
}
</style>
