<template>
  <pc-modal v-model="show">
    <template slot="title">
      {{ $t('responding_to_reviews_title') }}
    </template>
    <template slot="content">
      <p class="mt-6 mb-8">{{ $t('responding_to_reviews_start') }}</p>
      <div class="flex flex-col space-y-8">
        <div class="flex space-x-4">
          <div class="review-tip">
            <p class="text-l font-bold md:mt-0">{{ $t('be_polite') }}</p>
            <span class="clear" />
            <p>{{ $t('thank_customer') }}</p>
          </div>
        </div>
        <div class="review-tip">
          <p class="text-l font-bold md:mt-0">{{ $t('be_professional') }}</p>
          <span class="clear" />
          <p>{{ $t('talk_about_issue') }}</p>
        </div>
        <div class="review-tip">
          <p class="text-l font-bold md:mt-0">{{ $t('be_patient') }}</p>
          <span class="clear" />
          <p>{{ $t('dont_be_defensive') }}</p>
        </div>
        <div class="review-tip">
          <p class="text-l font-bold md:mt-0">{{ $t('be_proactive') }}</p>
          <p>{{ $t('take_the_opportunity') }}</p>
        </div>
      </div>
      <p class="mt-8">{{ $t('this_will_make') }}</p>
    </template>
  </pc-modal>
</template>

<script>
import { PcModal } from '@je-pc/ui-components'
export default {
  props: {
    isShown: {
      type: Boolean,
      default: false
    }
  },
  components: {
    PcModal
  },
  computed: {
    show: {
      get() {
        return this.isShown
      },
      set(value) {
        this.$emit('close', value)
      }
    }
  }
}
</script>

<style scoped>
.review-tip svg {
  float: left;
  margin-right: 16px;
}

.clear {
  clear: both;
}

@media screen and (max-width: 640px) {
  .review-tip svg {
    width: 40px;
    height: 40px;
  }
}
</style>
