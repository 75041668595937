<template>
  <pc-error-boundary @on-error="handleComponentError">
    <div class="px-4 sm:px-6 pb-4 sm:pb-6">
      <pc-page-transition>
        <router-view />
      </pc-page-transition>
    </div>
  </pc-error-boundary>
</template>

<script>
import { PcErrorBoundary, PcPageTransition } from '@je-pc/ui-components'
import logger from '@/services/logger'

export default {
  components: {
    PcPageTransition,
    PcErrorBoundary
  },
  methods: {
    handleComponentError ({ error, loggerPayload }) {
      logger.error(error, loggerPayload)
    }
  }
}
</script>
