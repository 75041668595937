<template>
  <div class="bg-white shadow-md rounded-xl p-4 flex flex-col">
    <div class="flex">
      <div class="flex flex-col flex-auto">
        <p class="text-xl font-bold leading-snug">{{ $t('your_rating') }}</p>
        <p
          class="flex flex-col lg:flex-row lg:items-center leading-none font-bold text-4xl mt-4"
        >
          {{ calculateScoreDisplay }}
          <pc-rating
            class="mt-2 md:mt-0 lg:ml-2"
            :width="20"
            :max="maxRating"
            :rating="calculateScoreDisplay"
          />
        </p>
        <p
          class="mt-2 text-grey-50 leading-normal text-sm lg:text-base"
          data-cy="reviewsAmount"
        >
          {{
            calculateScoreDisplay
              ? $t('based_on', { num: summary.ratingCountUsedInScore })
              : $t('no_reviews')
          }}
        </p>
      </div>
    </div>
    <template v-if="summary.ratingCountUsedInScore">
      <pie-divider
        class="my-4"
        variant="default"
        orientation="horizontal"
      ></pie-divider>
      <div>
        <pie-link
          @click="toggleInfo"
          class="w-full flex font-bold items-center justify-between cursor-pointer leading-normal"
          tag="button"
          rel="noopener"
          variant="default"
          isstandalone
          underline="reversed"
          data-cy="openRatingBreakdown"
        >
          {{ isOpened ? $t('hide_breakdown') : $t('view_breakdown') }}
          <svg
            class="transition duration-300 ease-in-out w-6 h-6"
            :class="{ 'transform rotate-180': isOpened }"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 9l-7 7-7-7"
            ></path>
          </svg>
        </pie-link>
        <collapse-transition>
          <div v-if="isOpened">
            <donut-chart v-if="chartItems.length" :items="chartItems" />
            <p class="font-bold text-xl">{{ $t('what_customers_think') }}</p>
            <p class="text-sm text-grey-50 leading-normal mt-2">
              {{ $t('monthly_data', { num: summary.ratingCountUsedInScore }) }}
            </p>
            <div
              class="flex flex-col space-y-6 mt-6"
              data-cy="customerThoughts"
            >
              <rating-bar
                :name="$t('food_quality')"
                :percent="foodPositive"
              ></rating-bar>
              <rating-bar
                :name="$t('delivery_time')"
                :percent="deliveryPositive"
              ></rating-bar>
            </div>
          </div>
        </collapse-transition>
      </div>
    </template>
  </div>
</template>

<script>
import '@justeattakeaway/pie-webc/components/link.js'
import '@justeattakeaway/pie-webc/components/divider.js'
import { PcRating, CollapseTransition } from '@je-pc/ui-components'
import { trackCustomEvent } from '@je-pc/tracking'
import { mapGetters, mapState } from 'vuex'
import RatingBar from '@/components/RatingBar.vue'
import DonutChart from '@/components/DonutChart.vue'
import { getMaxRating } from '../utils/maxRating'

export default {
  data() {
    return {
      isOpened: false
    }
  },
  components: {
    PcRating,
    CollapseTransition,
    RatingBar,
    DonutChart
  },
  methods: {
    toggleInfo() {
      this.isOpened = !this.isOpened
      if (this.isOpened) {
        trackCustomEvent('trackEvent', { event_name: 'view_ratings_summary' })
      }
    },
    getReviewValues(summary) {
      if (!summary) return null
      const {
        goodRatingsCount,
        neutralRatingsCount,
        badRatingsCount,
        total = goodRatingsCount + neutralRatingsCount + badRatingsCount
      } = summary
      return {
        goodRatingsCount,
        neutralRatingsCount,
        badRatingsCount,
        total
      }
    }
  },
  computed: {
    ...mapState(['summary']),
    calculateScoreDisplay() {
      if (this.summary.ratingCountUsedInScore === 0) {
        return 0
      }
      return this.summary.ratingAverage
    },
    foodPositive() {
      const values = this.getReviewValues(this.summary.ratingsBreakdown.quality)
      if (!values.total) {
        return 0
      }
      return Math.ceil((values.goodRatingsCount * 100) / values.total)
    },
    deliveryPositive() {
      const values = this.getReviewValues(
        this.summary.ratingsBreakdown.delivery
      )
      if (!values.total) {
        return 0
      }
      return Math.ceil((values.goodRatingsCount * 100) / values.total)
    },
    chartItems() {
      const items = this.getReviewValues(this.summary.ratingsBreakdown.overall)
      return items
        ? [
            {
              label: 'positive',
              color: '#017a39',
              count: items.goodRatingsCount
            },
            {
              label: 'neutral',
              color: '#ffb727',
              count: items.neutralRatingsCount
            },
            {
              label: 'negative',
              color: '#d50525',
              count: items.badRatingsCount
            }
          ]
        : []
    },
    maxRating() {
      return getMaxRating()
    }
  }
}
</script>

<style scoped>
@media screen and (min-width: 1025px) {
  .rating-icon {
    width: 136px;
  }
}
</style>
