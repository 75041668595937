var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jet-card"},[_c('div',{staticClass:"grid grid-cols-1 md:grid-cols-2 leading-normal"},[_c('div',{staticClass:"flex flex-col justify-center space-y-2 mb-2 md:mb-0"},[_c('p',{staticClass:"text-xl font-bold",attrs:{"data-cy":"reviewerName"}},[_vm._v(" "+_vm._s(_vm.review.customerName)+" ")]),_c('p',{staticClass:"text-xl md:text-base font-bold inline-flex items-center"},[_c('span',{staticClass:"leading-none",attrs:{"data-cy":"userReviewMark"}},[_vm._v(" "+_vm._s(_vm.review.ratingAverage)+" ")]),_c('pc-rating',{staticClass:"ml-2",attrs:{"width":16,"max":_vm.maxRating,"rating":_vm.review.ratingAverage}})],1)]),_c('div',{staticClass:"flex flex-col justify-center md:items-end"},[_c('div',{staticClass:"md:order-2 mb-2 md:mb-0 inline-flex items-center"},[_c('pie-icon-button',{attrs:{"size":"small","variant":"ghost-secondary"},on:{"click":function($event){return _vm.copyIdToClipboard(_vm.review.friendlyOrderId)}}},[_c('span',{directives:[{name:"pc-tooltip",rawName:"v-pc-tooltip",value:({
              placement: 'bottom',
              content: _vm.$t('copy_order_number')
            }),expression:"{\n              placement: 'bottom',\n              content: $t('copy_order_number')\n            }"}]},[_c('icon-copy')],1)]),_c('pie-link',{attrs:{"rel":"noopener","tag":"a","variant":"default","data-cy":"orderlink","href":_vm.getOrderLink(_vm.review.friendlyOrderId)}},[_vm._v(" "+_vm._s(_vm.$t('order_n', { num: _vm.review.friendlyOrderId }))+" ")])],1),_c('p',{staticClass:"text-grey-50 text-base md:order-1 md:mb-2",attrs:{"data-cy":"reviewDate"}},[_vm._v(" "+_vm._s(_vm.format(new Date(_vm.review.rateDate), 'd MMM yyyy'))+" ")])])]),_c('p',{staticClass:"mt-4",attrs:{"data-cy":"reviewerComment"}},[_vm._v(" "+_vm._s(_vm.review.customerComments)+" ")]),(_vm.isReplyEnabled)?[(_vm.review.restaurantComments)?_c('div',{staticClass:"mt-4 break-words p-4 support-neutral rounded-xl flex flex-col relative",attrs:{"data-cy":"userReviewText"}},[_c('p',{staticClass:"font-bold text-base mb-4 inline-flex items-start sm:items-center justify-between flex-col sm:flex-row",attrs:{"data-cy":"youRepliedText"}},[_vm._v(" "+_vm._s(_vm.$t('you_replied'))+" "),(!_vm.review.approvedRestaurantComments)?_c('span',{directives:[{name:"pc-tooltip",rawName:"v-pc-tooltip",value:({
            placement: 'right',
            content: _vm.$t('all_reviews_checked')
          }),expression:"{\n            placement: 'right',\n            content: $t('all_reviews_checked')\n          }"}],staticClass:"font-normal cursor-pointer container-inverse text-sm px-2 py-1 rounded-xl leading-none inline-flex items-center space-x-1 mt-2 sm:mt-0",attrs:{"data-cy":"pendingApprovalText"}},[_c('span',[_vm._v(_vm._s(_vm.$t('pending_approval')))])]):_vm._e()]),_vm._v(" "+_vm._s(_vm.review.approvedRestaurantComments || _vm.review.restaurantComments)+" ")]):_c('review-response',{attrs:{"id":_vm.review.id},on:{"edit":_vm.handleEdit}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }