<template>
  <div class="container mt-6">
    <h1 class="pc-page-title mb-4 md:mb-6">
      {{ $t('ratings_and_reviews') }}
    </h1>
    <div
      class="flex flex-col md:flex-row space-x-0 md:space-x-6"
      v-if="!isLoading"
    >
      <reviews-list
        :is-reply-enabled="isReplyEnabled"
        class="flex-auto order-2 md:order-none w-full md:w-6/12 lg:w-3/6"
      />

      <review-tip
        v-show="reviewsCount > 10"
        v-if="isReplyEnabled"
        @show="isShown = true"
        class="flex sm:hidden flex-grow-0 order-3 md:order-none mt-4"
      />

      <div class="w-full md:w-5/12 lg:w-2/6">

        <!-- show icon if no reviews -->
        <no-reviews-icon v-show="!reviewsCount"/>

        <!-- only show rating if there are reviews -->
        <your-rating v-show="reviewsCount" class="flex-grow-0 order-1 md:order-none" />

        <!-- if 1-10 reviews show offer link on the side -->
        <more-orders-more-reviews
          v-show="reviewsCount <= 10 && reviewsCount > 0"
          class="hidden sm:flex flex-grow-0 order-3 md:order-none mt-4"
        />

        <!-- if > 10 reviews show responding to reviews modal -->
        <review-tip
          v-show="reviewsCount > 10"
          v-if="isReplyEnabled"
          @show="isShown = true"
          class="hidden sm:flex flex-grow-0 order-3 md:order-none mt-4"
        />
      </div>
    </div>
    <loader v-else />
    <reviews-modal
      v-if="isReplyEnabled"
      @close="isShown = false"
      :isShown="isShown"
    />
  </div>
</template>

<script>
import YourRating from '../components/YourRating'
import ReviewsList from '../components/ReviewsList'
import ReviewTip from '../components/ReviewTip'
import ReviewsModal from '../components/ReviewsModal'
import MoreOrdersMoreReviews from '../components/MoreOrdersMoreReviews.vue'
import NoReviewsIcon from '@/icons/NoReviewsIcon.vue'
import Loader from '../components/Loader'
import { mapState, mapGetters } from 'vuex'
import { GET_REVIEWS, GET_REVIEWS_SUMMARY } from '@/store/actions.types'
import { getFeature } from '../services/featuremanagement'

export default {
  name: 'Home',
  components: {
    YourRating,
    ReviewsList,
    ReviewTip,
    ReviewsModal,
    Loader,
    MoreOrdersMoreReviews,
    NoReviewsIcon
  },
  data() {
    return {
      isShown: false,
      isLoading: false,
      isReplyEnabled: false,
      isAAExperimentEnabled: false
    }
  },
  async created() {
    this.isLoading = true
    await this.$store.dispatch(GET_REVIEWS_SUMMARY)
    await this.$store.dispatch(GET_REVIEWS)
    this.initFeatureValue()
    this.initAAExperiment()
    this.isLoading = false
  },
  computed: {
    ...mapState(['reviews']),
    ...mapGetters(['reviewsCount'])
  },
  methods: {
    initFeatureValue() {
      getFeature('restaurant-replies', Boolean).then(result => {
        this.isReplyEnabled = result ?? false
      })
    },
    initAAExperiment() {
      getFeature('partner-reviews-aa-test-feature', Boolean).then(result => {
        this.isAAExperimentEnabled = result ?? false //test that AA Experiment is being invoked
      })
    }
  }
}
</script>
