<template>
  <div class="p-4 notification rounded-xl shadow-md flex">
    <div class="flex justify-center">
      <icon-bulb-lightning-large fill="#125FCA" />
    </div>
    <div class="flex flex-col flex-auto">
      <div class="leading-normal text-base md:text-l">
        <p class="font-bold text-xl md:text-xl" data-cy="moreOrderMoreReviewsText">
          {{ $t('more_orders_more_reviews') }}
        </p>
        <p>
          {{ $t('more_orders_more_reviews_text') }}
        </p>
      </div>

      <pie-link
        tag="a"
        variant="high-visibility"
        size="medium"
        isBold
        underline="reversed"
        isStandalone
        data-cy="respondingToReviews"
        href="../marketing"
      >

        {{ $t('create_an_offer') }}
      </pie-link>
    </div>
  </div>
</template>

<script>
import '@justeattakeaway/pie-icons-webc/dist/IconBulbLightningLarge.js'
import '@justeattakeaway/pie-webc/components/link.js'
export default {
}
</script>

<style scoped>
/* see https://pie.design/foundations/colour/tokens/alias/light/ */

.notification {
  background-color: var(--dt-color-support-info-02);
}
</style>
