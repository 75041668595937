<template>
  <div class="jet-card">
    <div class="grid grid-cols-1 md:grid-cols-2 leading-normal">
      <div class="flex flex-col justify-center space-y-2 mb-2 md:mb-0">
        <p class="text-xl font-bold" data-cy="reviewerName">
          {{ review.customerName }}
        </p>
        <p class="text-xl md:text-base font-bold inline-flex items-center">
          <span class="leading-none" data-cy="userReviewMark">
            {{ review.ratingAverage }}
          </span>
          <pc-rating
            class="ml-2"
            :width="16"
            :max="maxRating"
            :rating="review.ratingAverage"
          />
        </p>
      </div>
      <div class="flex flex-col justify-center md:items-end">
        <div class="md:order-2 mb-2 md:mb-0 inline-flex items-center">
          <pie-icon-button
            @click="copyIdToClipboard(review.friendlyOrderId)"
            size="small"
            variant="ghost-secondary"
          >
            <span
              v-pc-tooltip="{
                placement: 'bottom',
                content: $t('copy_order_number')
              }"
            >
              <icon-copy></icon-copy>
            </span>
          </pie-icon-button>
          <pie-link
            rel="noopener"
            tag="a"
            variant="default"
            data-cy="orderlink"
            :href="getOrderLink(review.friendlyOrderId)"
          >
            {{ $t('order_n', { num: review.friendlyOrderId }) }}
          </pie-link>
        </div>

        <p
          class="text-grey-50 text-base md:order-1 md:mb-2"
          data-cy="reviewDate"
        >
          {{ format(new Date(review.rateDate), 'd MMM yyyy') }}
        </p>
      </div>
    </div>
    <p class="mt-4" data-cy="reviewerComment">
      {{ review.customerComments }}
    </p>
    <template v-if="isReplyEnabled">
      <div
        v-if="review.restaurantComments"
        class="mt-4 break-words p-4 support-neutral rounded-xl flex flex-col relative"
        data-cy="userReviewText"
      >
        <p
          class="font-bold text-base mb-4 inline-flex items-start sm:items-center justify-between flex-col sm:flex-row"
          data-cy="youRepliedText"
        >
          {{ $t('you_replied') }}
          <span
            v-if="!review.approvedRestaurantComments"
            v-pc-tooltip="{
              placement: 'right',
              content: $t('all_reviews_checked')
            }"
            class="font-normal cursor-pointer container-inverse text-sm px-2 py-1 rounded-xl leading-none inline-flex items-center space-x-1 mt-2 sm:mt-0"
            data-cy="pendingApprovalText"
          >
            <span>{{ $t('pending_approval') }}</span>
          </span>
        </p>
        {{ review.approvedRestaurantComments || review.restaurantComments }}
      </div>
      <review-response @edit="handleEdit" :id="review.id" v-else />
    </template>
  </div>
</template>

<script>
import '@justeattakeaway/pie-webc/components/icon-button.js'
import '@justeattakeaway/pie-icons-webc/dist/IconCopy.js'
import '@justeattakeaway/pie-webc/components/link.js'
import { format } from 'date-fns'
import { PcRating } from '@je-pc/ui-components'
import ReviewResponse from './ReviewResponse'
import { getMaxRating } from '../utils/maxRating'

export default {
  data() {
    return {
      format
    }
  },
  props: {
    review: {
      type: Object,
      required: true
    },
    isReplyEnabled: {
      type: Boolean,
      required: true
    }
  },
  components: {
    PcRating,
    ReviewResponse
  },
  computed: {
    maxRating() {
      return getMaxRating()
    }
  },
  methods: {
    getOrderLink(id) {
      return `/orders-and-invoices/order-history/${id}?referer=/reviews`
    },
    handleEdit() {
      this.$el.scrollIntoView({ behavior: 'smooth' })
    },
    copyIdToClipboard(id) {
      navigator.clipboard.writeText(id)
    }
  }
}
</script>

<style scoped>
.chat:after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 0 10px 10px 0;
  border-color: transparent #e7f1fe transparent transparent;
  bottom: -10px;
  right: 0;
}

.jet-card {
  position: relative;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.03),
    0 3px 1px -2px rgba(0, 0, 0, 0.07), 0 1px 5px 0 rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  padding: 16px;
  border: 1px solid transparent;
}

/* see https://pie.design/foundations/colour/tokens/alias/light/ */

.support-neutral {
  background-color: var(--dt-color-container-strong);
}
.container-inverse {
  background-color: var(--dt-color-container-inverse);
  color: var(--dt-color-container-default);
}

#copyIcon:hover {
  cursor: pointer;
}
</style>
