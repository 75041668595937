<template>
  <div>
    <h1 class="pc-page-title md:hidden my-4">{{ $t('reviews') }}</h1>
    <template v-if="reviewsCount">
      <div class="space-y-4">
        <review-card
          :is-reply-enabled="isReplyEnabled"
          :key="i"
          v-for="(review, i) in reviews"
          :review="review"
        />
      </div>
      <div ref="loadingObserver" v-show="!isLoadingInfinite"></div>
      <pc-back-to-top-button button-class="md:ml-6" :text="$t('back_to_top')" />
    </template>
    <no-reviews v-else />
  </div>
</template>

<script>
import { PcBackToTopButton, pageTitle } from '@je-pc/ui-components'
import { mapState, mapGetters } from 'vuex'
import { GET_REVIEWS_NEXT } from '../store/actions.types'
import ReviewCard from '../components/ReviewCard.vue'
import NoReviews from '@/components/NoReviews.vue'

export default {
  title() {
    return this.$t('reviews')
  },
  components: {
    ReviewCard,
    NoReviews,
    PcBackToTopButton
  },
  mixins: [pageTitle],
  props: {
    isReplyEnabled: {
      type: Boolean,
      required: true
    }
  },
  mounted() {
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting) {
        this.handleInfinityScroll()
      }
    })

    if (this.reviewsCount) {
      const element = this.$refs.loadingObserver
      this.observer.observe(element)
    }
  },
  destroyed() {
    if (this.observer) {
      this.observer.disconnect()
    }
  },
  data() {
    return {
      observer: null
    }
  },
  computed: {
    ...mapState(['reviews']),
    ...mapGetters(['reviewsCount']),
    isLoadingInfinite() {
      return this.$store.state.isLoadingInfinite
    }
  },
  methods: {
    async handleInfinityScroll() {
      await this.$store.dispatch(GET_REVIEWS_NEXT)
    }
  }
}
</script>
