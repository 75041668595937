<template>
  <div class="p-4 notification rounded-xl shadow-md flex">
    <div class="flex justify-center p-1">
      <icon-info-circle-large fill="#125FCA" />
    </div>
    <div class="flex flex-col flex-1 mt-1 p-1">
      <p class="text-l font-bold leading-snug">
        {{ $t('responding_to_reviews_title') }}
      </p>
      {{ $t('responding_to_reviews') }}
      <pie-link
        tag="button"
        variant="high-visibility"
        size="medium"
        data-cy="respondingToReviews"
        rel="noopener"
        target="_blank"
        @click="$emit('show')"
      >
        {{ $t('read_more') }}
      </pie-link>
      <!-- TODO - this was previously used, might be needed for the modal - data-cy="respondingToReviewsTextBlockClose" -->
    </div>
  </div>
</template>

<script>
import '@justeattakeaway/pie-icons-webc/dist/IconInfoCircleLarge.js'
import '@justeattakeaway/pie-webc/components/link.js'
export default {
}
</script>

<style>
/* see https://pie.design/foundations/colour/tokens/alias/light/ */

.notification {
  background-color: var(--dt-color-support-info-02);
}
</style>
